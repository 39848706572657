


#new-phone{
  width: 90%;
  height: 100%;
  margin-left: 0%;
}
#paypal{
  position: absolute;
  left: 5%;
  top: 65%;
  width: 25%;
  height: 25%;
  z-index: 100;
}

#second-phone{
  padding-left: 5%;
  padding-top: 10%;
  right: 10%;
  
}
#second-phone>p{
  color: chocolate;
  font-weight: bold;
  font-size: 25pt;
  font-family: Arial, Helvetica, sans-serif;
}

  #searchBar{
    border: 0;
  }
  #inputBar::-webkit-input-placeholder {
    font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: hsl(9, 40%, 60%);
  }
  
  .currentGiviesText{
    display: flex;
    left: 80%;
    margin-bottom: 1%;
    font-weight: bold;
  }
  
  #logoAppStore{
    max-height: 10em;
    position: relative;
    top: 7.5em;
    left: 2.5em;
    transform: translate(-50%, -50%);
  }
  .textAppStore{
    font-family: SFProDisplay-Regular;
    font-size: 30pt;
    color: white;
    max-width: 21.75em;
    position: relative;
    top: 5em;
    left: 13.5em;
    transform: translate(-50%, -50%);
  }
  #logoIOS{
    position: relative;
    top: 12em;
    left: 2.5em;
    transform: translate(-50%, -50%);
  }
  #logoAndroid{
    position: relative;
    top: 12em;
    left: 10.5em;
    transform: translate(-50%, -50%);
  }
  .txtGS{
    font-weight: bold;
    line-height: 36pt;
  }
  .txtAS{
    font-weight: bold;
    line-height: 36pt;
  }
  
  
  
  