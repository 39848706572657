*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.4s;
  font-family: -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
}

.container{
  /* margin-left: 5%;
  margin-right: 5%; */
}

/* Navbar section */

.nav{
  width: 100%;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-image: linear-gradient(to right, #484D6D -5%, #FF8181 110%);
}

.nav div.logo{
  width: 180px;
  height: 10px;
  position: absolute;
}

.nav div.logo a{
  text-decoration: none;
  color: #fff;
  font-size: 1.8em;
  text-transform: uppercase;
}

.nav div.logo a:hover {
  color: #c0c0c0;
}

.nav div.main_list{
  position: relative;
  left: 70%;
  /* width: 400px;
  height: 65px;
  float: right; */
}

.nav div.main_list ul{
  width:350px;
  height: 65px;
  display: flex;
  list-style: none;
}

.nav div.main_list ul li{
  margin-left: 10%;
  height: 65px;
  
}

.nav div.main_list ul li a{
  text-decoration: none;
  color: #fff;
  line-height: 65px;
  
  
}

.nav div.main_list ul li a:hover{
  color: #c0c0c0;
}

.nav div.media_button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
  display: none;
}

.nav div.media_button button.main_media_button {
  width: 100%;
  height: 100%;
  background-color: transparent;;
  outline: 0;
  border: none;
  cursor: pointer;
}

.nav div.media_button button.main_media_button span{
  width: 98%;
  height: 1px;
  display: block;
  background-color: #fff;
  margin-top: 9px;
  margin-bottom: 10px;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(1){
  transform: rotateY(180deg);
  transition: all 0.5s;
  background-color: #c0c0c0;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(2){
  transform: rotateY(180deg);
  transition: all 0.4s;
  background-color: #c0c0c0;
}

.nav div.media_button button.main_media_button:hover span:nth-of-type(3){
  transform: rotateY(180deg);
  transition: all 0.3s;
  background-color: #c0c0c0;
}

.nav div.media_button button.active span:nth-of-type(1) {
  transform: rotate3d(0, 0, 1, 45deg);
  position: absolute;
  margin: 0;
}

.nav div.media_button button.active span:nth-of-type(2) {
  display: none;
}

.nav div.media_button button.active span:nth-of-type(3) {
  transform: rotate3d(0, 0, 1, -45deg);
  position: absolute;
  margin: 0;
}

.nav div.media_button button.active:hover span:nth-of-type(1) {
  transform: rotate3d(0, 0, 1, 20deg);
}

.nav div.media_button button.active:hover span:nth-of-type(3) {
  transform: rotate3d(0, 0, 1, -20deg);
}

/* Home section */

.home{
  width: 100%;
  height: 100vh;
  background-color: #ddd;
}

/* Medai qurey section */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  
  .container{
      margin: 0;
  }
  
}




@media screen and (max-width:768px) {
  
  .container{
      margin: 0;
  }
  
  .nav div.logo{
      margin-left: 15px;
  }
  
  .nav div.main_list{
      width: 100%;
      margin-top: 65px;
      height: 0px;
      overflow: hidden;
  }
  
  .nav div.show_list{
      height: 200px;
  }
  
  .nav div.main_list ul{
      flex-direction: column;
      width: 100%;
      height: 200px;
      top: 80px;
      right: 0;
      left: 0;
  }
  
  .nav div.main_list ul li{
      width: 100%;
      height: 40px;
      background-color:rgba(0,0,255,0.8);
  }
  
  .nav div.main_list ul li a{
    text-align: center;
      line-height: 40px;
      width: 100%;
      height: 40px;
      display: table;
  }
  
  .nav div.media_button{
      display: block;
  }
}

#logo{
  position: relative;
  max-height: 50pt;
  height: 30pt;
  padding: 1%;
  
  left: 75%;
  
}
.nav-logo{
  position: fixed;
  top: 0;
  width: 100%;
  padding-bottom: 0%;
  background-color: white !important;
}
.mr-auto{
  position: relative;
  left: 50%;
}

@media only screen and (max-width: 600px) {
  #logo{
    max-height: 50pt;
    height: 30pt;
    padding: 1%;
    left: 35%;
  }
  .nav-logo{
    position: fixed !important;
    top: 0;
    width: 100%;
    padding-bottom: 2.5%;
    background-color: white !important;
    z-index: 12;
  }
  
}

