:root {
  --bg-color: #1e1e2f;
  --button-color: #3e3e5c;
  --button-hover: #57577e;
  --text-color: #ffffff;
  --animation-duration: 0.5s;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  background-image: url('./assets/Givvy bg.jpg');
  background-size: cover;
  /* Para cubrir todo el fondo */
  background-position: center;
  /* Centrar la imagen */
  background-repeat: no-repeat;
  /* No repetir la imagen */
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.greeting {
  animation: fadeIn2 var(--animation-duration) ease-in-out;
}

.greeting h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}


.btn {
  padding: 0.8rem 1.5rem;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color var(--animation-duration);
}

.btn:hover {
  background-color: var(--button-hover);
}

.btn.start-chat {
  animation: slideInFromLeft var(--animation-duration) ease-out;
}

.btn.old-queries {
  animation: slideInFromRight var(--animation-duration) ease-out;
}

@keyframes fadeIn2 {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .greeting h1 {
    font-size: 1.5rem;
  }

  .btn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}


body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #1e1e2f 40%, #0c0c15 100%);
  font-family: 'Arial', sans-serif;
  color: white;
  background-image: url('./assets/Givvy bg.jpg');
  background-size: cover;
  /* Para cubrir todo el fondo */
  background-position: center;
  /* Centrar la imagen */
  background-repeat: no-repeat;
  /* No repetir la imagen */
}

.app-container {
  text-align: center;
}

.welcome {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.description {
  font-size: 1.2rem;
  color: #c0c0c0;
  margin: 10px 0 20px 0;
}

.robot-container {
  margin: 20px 0;
}

.robot-container img {
  width: auto;
  max-width: 700px;
  height: auto;
  /* Ensures the aspect ratio is preserved */
  margin: 0 auto;
  display: block;
  animation: glow 3s infinite ease-in-out;
}

@keyframes glow {
  0%, 100% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }

  50% {
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 1));
  }
}

.button-container {
  display: flex;
  width: 750px;
  justify-content: left;
  gap: 20px;
  margin-top: 20px;
}

.btn {
  padding: 15px 40px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  /* Rounded edges */
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
}

.start-chat {
  background: linear-gradient(to right, #6a40ff, #9f60ff);
  /* Gradient background */
}

.start-chat:hover {
  background: linear-gradient(to right, #5936e0, #8c53e0);
  /* Darker gradient on hover */
}

.old-queries {
  background: linear-gradient(to right, #ff4e6a, #ff6b88);
  /* Gradient background */
}

.old-queries:hover {
  background: linear-gradient(to right, #e0445c, #e05f78);
  /* Darker gradient on hover */
}

.text-container {
  text-align: left;
  /* Align text to the left */
  margin-left: 20px;
  /* Add margin for spacing */
  color: white;
  /* Default text color */
}

.welcome-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #b97cff;
  /* Light purple color for "WELCOME" */
  margin-bottom: 10px;
}

.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  /* Main title in white */
  margin-bottom: 10px;
  line-height: 1.2;
  /* Adjust line spacing */
}

.subtext {
  font-size: 1rem;
  color: #a9a9a9;
  /* Subtle gray color for description */
  line-height: 1.5;
  /* Improve readability */
  max-width: 600px;
  /* Optional: Limit text width */
}

.text-container {
  width: 500px;
  text-align: left;
  /* Keeps the text aligned to the left */
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #1e1e2f 40%, #0c0c15 100%);
  font-family: 'Arial', sans-serif;
  color: white;
  background-image: url('./assets/Givvy bg.jpg');
  background-size: cover;
  /* Para cubrir todo el fondo */
  background-position: center;
  /* Centrar la imagen */
  background-repeat: no-repeat;
  /* No repetir la imagen */
}

.robot-container img {
  width: auto;
  max-width: 400px;
  height: auto;
  /* Ensures the aspect ratio is preserved */
  margin: 0 auto;
  display: block;
  animation: glow 3s infinite ease-in-out;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.robot-container img {
  max-width: 100%;
  /* Dynamically scale the image size for smaller screens */
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  max-width: 400px;
}

.text-container {
  text-align: center;
  /* Keeps the text aligned to the left */
  width: 400px;
  margin: 0 auto;
}

.input-container {
  display: flex;
  align-items: center;
  border-radius: 36px !important;
  border: 0.5px solid #7571B6;
  /* background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
  box-shadow: 0px 4px 51.1px 0px #090818;
  backdrop-filter: blur(22.5px); */
  /* padding: 10px 15px; */
  width: auto;
  height: 45px;
  margin-bottom: 0 auto;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
  width: 40px;
  height: 40px;

  /* font-size: 18px; */
}

.custom-input {
  flex: 1;
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.custom-input::placeholder {
  color: #595779 !important;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 285.714% */
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.submit-button {
  /* background-color: #3ae35c; */
  border: none;
  /* border-radius: 50%; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: white; */
  cursor: pointer;
  /* font-size: 18px; */
}

/* .submit-button:hover {
  background-color: #32c34f;
} */

.select-container {
  display: flex;
  align-items: center;
  border-radius: 36px !important;
  border: 0.5px solid #7571B6;
  /* padding: 10px 15px; */
  width: 75%;
  height: 45px;
  margin-bottom: 5% !important;
}

.custom-select-m {
  flex: 1;
  background: transparent !important;
  box-shadow: none !important;
  backdrop-filter: blur(22.5px);

}

.custom-select-m option {
  color: #595779 !important;
  background: transparent !important;

}

.icon2 img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
  width: 40px !important;
  height: 40px !important;

  font-size: 18px;
}

.submit-button2 {
  z-index: 0;
  /* background-color: #3ae35c; */
  border: none;
  /* border-radius: 50%; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: white; */
  /* font-size: 18px; */
}

.inputs-custom-m {
  position: absolute !important;
  /* bottom: -65% !important; */
  bottom: 5%;
  width: 95%;

}

.input-container-desc {
  display: flex;
  align-items: center;
  border-radius: 36px !important;
  border: 0.5px solid #7571B6;
  /* background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
  box-shadow: 0px 4px 51.1px 0px #090818;
  backdrop-filter: blur(22.5px); */
  /* padding: 10px 15px; */

  width: 550px;
  height: 45px;
  margin: 0 auto;
  /* Centrado horizontal */
  position: fixed;
  /* Fija el elemento */
  bottom: 40px;
  /* Espacio desde la parte inferior */
  left: 50%;
  /* Centro relativo al ancho */
  transform: translateX(-50%);
  /* Corrección para centrar */
  z-index: 1000;
  /* Asegura que esté por encima de otros elementos */


}

.separator {
  height: 100px !important;
}


.custom-butt {
  border-radius: 43px;
  border: 0.5px solid #EB9BF6;
  background: linear-gradient(180deg, #CB79DB 0%, #B764B7 93.05%);
  color: #F4EAFD !important;

  text-align: center;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.20);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.custom-butt-back {
  border-radius: 43px;
  border: 0.5px solid #FFA178;
  background: linear-gradient(180deg, #FF8450 0%, #FF5812 93.05%);
  color: #F4EAFD !important;

  text-align: center;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.20);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}

.text-msg {
  color: #FFF !important;

  font-family: Montserrat !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.72px;
}

.chat-custom-m {
  border-radius: 20px;
  border: 0.5px solid #7571B6;
  background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
  box-shadow: 0px 4px 51.1px 0px #090818;
  /* backdrop-filter: blur(22.5px); */
}

.main-chat-container {
  padding: 20px;
  font-family: 'Arial, sans-serif';
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.fixed-button-close {
  position: absolute;
  top: 1%;
  right: 29.5%;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.bluur {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
.autoAi {
  position: fixed;
  bottom: 3.5%;
  left: 50%;
  /* Centro relativo al ancho */
  transform: translateX(-50%);
  font-size: 15px;
  color: #595779;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #1e1e2f 40%, #0c0c15 100%);
    font-family: 'Arial', sans-serif;
    color: white;
    background-image: url('./assets/Givvy bg.jpg');
    background-size: cover;
    /* Para cubrir todo el fondo */
    background-position: center;
    /* Centrar la imagen */
    background-repeat: no-repeat;
    /* No repetir la imagen */
  }

  .robot-container img {
    width: auto;
    max-width: 300px;
    height: auto;
    /* Ensures the aspect ratio is preserved */
    margin: 0 auto;
    display: block;
    animation: glow 3s infinite ease-in-out;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }

  .robot-container img {
    max-width: 80%;
    /* Dynamically scale the image size for smaller screens */
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px !important;
    max-width: 400px;
  }

  .text-container {
    text-align: center;
    /* Keeps the text aligned to the left */
    width: 400px;
    margin: 0 auto;
  }

  .input-container {
    display: flex;
    align-items: center;
    border-radius: 36px !important;
    border: 0.5px solid #7571B6;
    /* background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
    box-shadow: 0px 4px 51.1px 0px #090818;
    backdrop-filter: blur(22.5px); */
    /* padding: 10px 15px; */
    width: auto;
    height: 45px;
    margin-bottom: 0 auto;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%; */
    width: 40px;
    height: 40px;

    /* font-size: 18px; */
  }

  .custom-input {
    flex: 1;
    background-color: transparent !important;
    border: none;
    outline: none;
    padding: 10px;
    color: #ffffff;
    font-size: 16px;
    font-family: 'Arial', sans-serif;

  }

  .custom-input::placeholder {
    color: #595779 !important;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 285.714% */
    letter-spacing: 0.84px;
    text-transform: capitalize;
  }

  .submit-button {
    /* background-color: #3ae35c; */
    border: none;
    /* border-radius: 50%; */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    cursor: pointer;
    /* font-size: 18px; */
  }

  /* .submit-button:hover {
    background-color: #32c34f;
  } */

  .select-container {
    display: flex;
    align-items: center;
    border-radius: 36px !important;
    border: 0.5px solid #7571B6;
    /* padding: 10px 15px; */
    width: 90%;
    height: 45px;
    margin-bottom: 5% !important;
  }

  .custom-select-m {
    flex: 1;
    background: transparent !important;
    box-shadow: none !important;
    backdrop-filter: blur(22.5px);

  }

  .custom-select-m option {
    color: #595779 !important;
    background: transparent !important;
  }

  .icon2 img {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%; */
    width: 40px !important;
    height: 40px !important;

    font-size: 18px;
  }

  .submit-button2 {
    z-index: 0;
    /* background-color: #3ae35c; */
    border: none;
    /* border-radius: 50%; */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    /* font-size: 18px; */
  }

  .inputs-custom-m {
    position: absolute !important;
    /* bottom: -65% !important; */
    top: 82.5% !important;
    width: 92.5%
  }

  .input-container-desc {
    display: flex;
    align-items: center;
    border-radius: 36px !important;
    border: 0.5px solid #7571B6;
    /* background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
    box-shadow: 0px 4px 51.1px 0px #090818;
    backdrop-filter: blur(22.5px); */
    /* padding: 10px 15px; */

    width: 85%;
    height: 45px;
    margin: 0 auto;
    /* Centrado horizontal */
    position: fixed;
    /* Fija el elemento */
    bottom: 70px;
    /* Espacio desde la parte inferior */
    left: 50%;
    /* Centro relativo al ancho */
    transform: translateX(-50%);
    /* Corrección para centrar */
    z-index: 1000;
    /* Asegura que esté por encima de otros elementos */


  }

  .separator {
    height: 100px !important;
  }

  .main-chat-container {
    padding: 20px;
    font-family: 'Arial, sans-serif';
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .fixed-button-close {
    position: absolute;
    top: 6%;
    right: 2.5%;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .autoAi {
    position: absolute;
    top: 97.5%;
    color: #595779;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    /* 333.333% */
    text-transform: capitalize;
    font-size: 7px;
    /* Centro relativo al ancho */
    /* transform: translateX(-50%); */
  }
}




.custom-input:-webkit-autofill,
.custom-input:-webkit-autofill:hover,
.custom-input:-webkit-autofill:focus,
.custom-input:-webkit-autofill:active {
  background-color: #1a1a1a !important;
  /* Dark background */
  color: #ffffff !important;
  /* White text */
  -webkit-text-fill-color: #ffffff !important;
  /* Ensures text remains white */
  transition: background-color 5000s ease-in-out 0s !important;
}

