
.match-status {
	background-color: #fdeaec;
	color: #d72641;
	padding: 8px 12px;
	border-radius: 6px;
	font-weight: 600;
	font-size: 10px;
	display: flex;
	align-items: center;
	line-height: 1;
	margin-right: auto;
	&:before {
		content: "";
		display: block;
		width: 6px;
		height: 6px;
		background-color: currentColor;
		border-radius: 50%;
		margin-right: 8px;
	}
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.match-status-checked {
	background-color: #3bac4a;
	color: white;
	padding: 0px 5px 0px 5px;
	border-radius: 6px;
	font-weight: 600;
	font-size: 10px;
	display: flex;
	align-items: center;
	line-height: 1;
	margin-right: auto;
}
.special-container{
  max-width: 100%;

}
.team {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.team-logo {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: var(--color-bg-primary);
	box-shadow: 0 4px 4px 0 rgba(#303030, 0.15),
		0 0 0 15px var(--color-bg-secondary);
	img {
		width: 50px;
	}
}
.team-name {
	text-align: center;
	margin-top: 10px;
	font-size: 12px;
	font-weight: 600;
}
.match-tournament {
	display: flex;
	align-items: center;
	font-weight: 600;
  font-size: 10px;
	img {
		width: 20px;
		margin-right: 12px;
	}
}
.match-details {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.match-date, .match-referee {
	font-size: 10px;
	color:  black;
	strong {
		color: var(--color-text-primary);
	}
}

*{padding:0;margin:0;}

body{
	// font-family:Verdana, Geneva, sans-serif;
	// font-size:18px;
	// background-color:#CCC;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}

.match-score {
	margin-top: 12px;
	display: flex;
	align-items: center;
}

.match-score-number {
	font-size: 20px;
	font-weight: 600;
	line-height: 1;
	&--leading {
		color: var(--color-theme-primary);
	}
}

.match-score-divider {
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
	color: var(--color-text-icon);
	margin-left: 10px;
	margin-right: 10px;
}

.match-time-lapsed {
	color: #DF9443;
	font-size: 14px;
	font-weight: 600;
	margin-top: 8px;
}

.match-referee {
	margin-top: 12px;
}

.match-bet-options {
	display: flex;
	margin-top: 8px;
	padding-bottom: 12px;
}

.match-bet-option {
	margin-left: 4px;
	margin-right: 4px;
	border: 1px solid var(--color-text-icon);
	background-color: #F9f9f9;
	border-radius: 2px;
	color: var(--color-text-secondary);
	font-size: 14px;
	font-weight: 600;
	padding: 4px 8px;
}

.match-bet-place {
	position: absolute;
	bottom: -16px;
	left: 50%;
	transform: translateX(-50%);
	border: 0;
	background-color: var(--color-theme-primary);
	border-radius: 6px;
	padding: 10px 48px;
	color: rgba(#fff, 0.9);
	font-size: 14px;
	box-shadow: 0 4px 8px 0 rgba(#303030, 0.25);
}
body {
  // background-color: #172b4d;
}
.chart{
  height: 500px !important;
}
.cards-gv * {
  color: black !important;
}
#hover-text{
  position: fixed;
  z-index: 100000000;
  background-color: black;
  color: white;
  display:none;
  top: 0
}
.hidden{
  display: none !important
}
// #hover-text:hover{
//   display: block
// }
.scrollbar-inner {
  background-color: #f5f5f5 !important;
  border: none;
}
#photoes {
  width: 75em;
  overflow-x: auto;
  white-space: nowrap;
}
.sidenav {
  border: none;
}
.header-body1 {
  position: relative;
  height: 30vh;
}
.mask1 {
  position: absolute;
  width: 18vw;
  left: 40vw;
  bottom: 0;
  margin-top: 3.5%;
  margin-left: calc(-80% / 2);
}
.mask2 {
  position: absolute;
  width: 8vw;
  left: 35vw;
  bottom: 0;
}
.mask3 {
  position: absolute;
  width: 5vw;
  bottom: 10vh;
}
.mask4 {
  position: absolute;
  width: 7.5vw;
  bottom: 5vh;
  right: 0;
}
.container-first-second-text {
  text-align: left;
  padding-left: 27.5vw;
  padding-top: 5vh;
}
.first-text {
  display: block;
  color: white;
  font-weight: bold;
  font-size: 2vw;
}
.second-text {
  display: block;
  color: white;
  font-size: 1.5vw;
}
// .bg-gradient-info-custom {
//   background: c
// }
// .bg-info {
//   background-color: #5e72e4 !important;
// }
// .bg-gradient-profile {
//   background-color: #5e72e4 !important;
// }

.promo {
  margin-left: 5rem;
  background-color: #c93b3b;
  color: white;
  font-weight: bold;
  border-radius: 2.5pt;
  padding: 0.1rem;
  box-shadow: 1px 1px 1px 1px #dfdede;
}
.promo-admin {
  position: absolute;
  margin-top: -10px !important;
  right: 430px;
  font-size: 5pt;
  background-color: #c93b3b;
  color: white;
  font-weight: bold;
  border-radius: 2.5pt;
  padding: 0.1rem;
}
.Background {
  width: 790px;
  height: 613px;
  margin: 154.8px 402px 107.2px 116.5px;
  padding: 23.2px 24px 29.5px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);
  background-color: #ffffff;
}

// DASHBOARD
.image-col {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding: 5% 0% 5% 2.5%;
  top: 50%;
}
.image-card {
  position: relative;
  max-width: 75%;
  max-height: 100%;
  border-radius: 100pt;
}
.name-app-coins {
  padding: 5px 0% 0% 0%;
}
.title-text {
  font-weight: bold;
  font-size: 1.25vw;
}
.coin-text {
  font-weight: bold;
  font-size: 1.25vw;
}
.coin-app-card {
  position: relative;
  width: 1.25vw;
  bottom: 0.15vw;
  left: 2.5%;
}
.app-not-downloaded {
  display: block;
  font-size: 0.75vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  line-height: 1vw !important;
}
.close-button {
  position: relative;
  right: 100%;
  font-size: 3em;
}
.open-sidenav > .sidenav-toggler-line {
  background-color: white !important;
}
.col-money {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding: 7.5% 0% 0% 0%;
  top: 30%;
}
.col-money > .money {
  font-size: 1vw;
}
.col-money > .col-12 {
  padding: 0;
}

.col-button {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding: 7% 0% 0% 5%;
  top: 50%;
  left: 5%;
}

.button-download {
  background-color: #dfdede;
  font-size: 0.75vw;
  padding: 5% 10% 5% 10%;
  color: black !important;
}
.button-go-app {
  background-color: #dfdede;
  font-size: 0.75vw;
  padding: 5% 22.5% 5% 22.5%;
  color: black !important;
}
.platform-card {
  position: absolute;
  left: 80%;
  top: 70%;
}
.platform-card > .app-store-app-card {
  width: 6%;
}
.platform-card > .android-app-card {
  width: 8%;
}

// EXCHANGE //
.container-test {
  text-align: center;
}
.col-title-text-exhcnage {
  padding: 0% 0% 0% 1%;
}
.title-text-exhcnage {
  font-weight: 600;
  color: #32325d;
  font-size: 10pt;
}
.col-convert-text-exchange {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}
.convert-text-exchange {
  font-size: 0.75vw;
  font-weight: 100;
  color: #767683;
}
.arrow-blue {
  width: 15%;
  padding: 0% 2.5% 0% 2.5% !important;
}
.convert-text-exchange-label {
  color: white;
  background-color: #5e72e4;
  padding: 0.5em 2.75em 0.5em 2.75em;
  border-radius: 0.5em;
  border: none;
}
.convert-text-exchange-label-mobile {
  font-size: 10pt;
  color: white;
  background-color: #5e72e4;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 0.5em;
  border: none;
}
.arrow-white-mobile {
  width: 7.5% !important;
}
.exchange-mobile {
  font-weight: bold;
}
.promo-exchange {
  position: absolute;
  margin-left: 15px;
  bottom: 23px;
  background-color: #c93b3b;
  color: white;
  font-weight: bold;
  font-size: 10px;
  border-radius: 2.5pt;
  padding: 0.1rem;
  box-shadow: 1px 1px 1px 1px #dfdede;
}
.promo-exchange-mobile {
  -webkit-transform: rotate(-10deg);
  position: absolute;
  margin-left: -15px;
  bottom: 20px;
  background-color: #c93b3b;
  color: white;
  font-weight: bold;
  font-size: 10px;
  border-radius: 2.5pt;
  padding: 0.1rem;
  box-shadow: 1px 1px 1px 1px #dfdede;
}
.arrow-white {
  width: 5%;
}
.column-icon-text {
  text-align: center;
}
.coin-reward-exchange {
  width: 61.75%;
  margin-top: 0.15vw;
  margin-left: 35%;
}
.col-you-have {
  padding: 0;
}
.internal-3 {
  padding: 0;
}
.test {
  padding: 0;
}
.coin-reward-exchange-from {
  width: 10%;
  margin-bottom: 3.5%;
}
.plus-reward-exchange {
  width: 15%;
  margin-bottom: 1%;
  cursor: pointer;
}
.plus-reward-exchange-mobile {
  position: relative;
  right: 50%;
  width: 150%;
  margin-bottom: 1%;
  cursor: pointer;
}
.exchange-icon-exchange-mobile {
  position: relative;
  top: 25%;
  width: 150%;
  margin-bottom: 0%;
  cursor: pointer;
}
.exchange-icon-exchange-mobile-2 {
  position: relative;
  top: 0%;
  width: 150%;
  margin-bottom: 0%;
  cursor: pointer;
}
.minus-reward-exchange {
  width: 15%;
  margin-bottom: 1%;
  cursor: pointer;
}
.minus-reward-exchange-mobile {
  position: relative;
  left: 50%;
  width: 150%;
  margin-bottom: 1%;
  cursor: pointer;
}
.coin-reward-exchange-text {
  font-size: 1.2vw;
  color: #525f7f;
}
.exchange-text-to {
  font-size: 1.2vw;
  color: #525f7f;
}

.coin-reward-exchange-text-from {
  display: inline-block;
  margin-top: 5%;
  font-size: 1.2vw;
  color: #525f7f;
}
.coin-reward-exchange-text-from-mobile {
  display: inline-block;
  font-size: 15pt;
  color: #525f7f;
}
.text-download {
  position: absolute;
  z-index: 999 !important;
  margin-top: -1%;
  color: #525f7f;
  opacity: 0.8;
}

.mt--8 {
  margin-top: -17rem !important;
}
.mt--12 {
  margin-top: -13.65% !important;
}

.closed {
  display: none !important;
}
.opened {
  display: block !important;
}

.card-body * {
  color: white;
}

.our-button {
  background-color: #5e72e4;
}
.our-button:hover {
  background-color: #5e72e4;
  color: white;
}
.input-group-merge * {
  color: black;
}
// WITHDRAW //
.merchant-id * {
  color: black !important;
  opacity: 0.6;
}
.text-info-merchant-id {
  text-decoration: underline;
  cursor: pointer;
}
.text-info-merchant-id:hover {
  color: black !important;
  text-decoration: underline;
  cursor: pointer;
}
.cont-withdraw-img {
  text-align: center;
  padding: 0 !important;
}
.merchan-id-img {
  position: relative;
  width: 100%;
}
.mobile-th-withdraw > th {
  padding-bottom: 15% !important;
}
.mobile-th-withdraw > td {
  padding-bottom: 15% !important;
}
.issue {
  border-radius: 5px;
  padding: 2px 0px;
  white-space: nowrap;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5em;
}
.issue-mobile {
  position: absolute;
  margin-left: 5%;
  margin-top: 20%;
  font-size: 2.5vw;
}
.table-dark .thead-dark > tr > .issue-th {
  width: 40%;
}
.table-dark .thead-dark > tr > .email-th {
  width: 40%;
}
.table-flush .thead-light > tr > .completion-offerwall {
  width: 100%;
}
.table-flush .thead-light > tr > .task-offerwall {
  width: 60%;
}
.table-flush .thead-light > tr > .reward-offerwall {
  width: 20%;
}
.table-flush .thead-light > tr > .status-offerwall {
  width: 60%;
}
.table-flush .thead-light > tr > .app-exchange {
  width: 15%;
}
.table-flush .thead-light > tr > .youHave-exchange {
  width: 15%;
}
.table-flush .thead-light > tr > .from-exchange {
  width: 25%;
}
.table-flush .thead-light > tr > .to-exchange {
  width: 20%;
}
.table-flush .thead-light > tr > .to-exchange-2 {
  width: 100%;
}
.table-flush .thead-light > tr > .convert-exchange {
  width: 30%;
}
.table-flush .thead-light > tr > .convert-exchange-withdraw {
  width: 30%;
}
.table-flush .thead-light > tr > .to-exchange-withdraw {
  width: 20%;
}

// ADMIN NAVBAR //

.coin-admin-nav {
  width: 3vh;
  margin-bottom: 0.25vh;
}
.list .app-logo {
  padding-left: 1% !important;
}

.coin-reward-offerwall {
  width: 15%;
  position: relative;
  bottom: 0.1rem;
}

.coin-reward-exchange-from {
  position: relative;
  bottom: 0rem;
  right: 0.15em;
}

.coin-reward-exchange-text-convert {
  font-size: 12.5pt;
  color: #525f7f;
}

.button-offerwall {
  background-color: #5e72e4;
  font-size: 7.5pt;
  color: white;
  padding: 5px;
  width: 120%;
  margin: 0 !important;
}
.button-exchange {
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 60% !important;
  margin: 0 !important;
}
.button-exchange:hover {
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 60% !important;
  margin: 0 !important;
}

.plus-button {
  border: 2px solid lightgrey;
  background-color: #fff;
  font-size: 16px;
  height: 1.75em;
  width: 1.5em;
  border-radius: 999px;
  position: relative;
  margin-left: 0.4em;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: grey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
}
.minus-button {
  border: 2px solid lightgrey;
  background-color: #fff;
  font-size: 16px;
  height: 1.75em;
  width: 1.5em;
  border-radius: 999px;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: grey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
}
.icon-app-text {
  position: relative;
  font-size: 7.5pt;
  bottom: 5%;
}
.two-apps-icon {
  margin-bottom: 5%;
}
.img-logo-exchange {
  width: 35%;
}
.img-logo-exchange-mobile {
  width: 100%;
  margin-top: 10%;
}
.app-not-download {
  display: inline-block;
  margin-top: 10%;
  font-size: 8pt;
  line-height: 10pt !important;
}
// .container-apps-exchange {
//   border: 1px solid red;
// }
.internal {
  text-align: center;
  justify-content: center;
}
.internal-money {
  text-align: center;
}
.container-convert {
  margin-top: 10%;
}
.coin-mobile-exchnage {
  width: 25%;
  margin-bottom: 5%;
}
.text-coins-mobile {
  font-size: 10pt;
  font-weight: bold;
}
.text-money-mobile {
  font-size: 15pt;
  font-weight: bold;
  margin-left: 7.5%;
}

.congrats-password {
  width: 50%;
}

// PROFILE //

.pl-lg-4 * {
  color: grey;
}
.save-button-profile {
  padding: 10px 25px 10px 25px;
  background-color: #5e72e4 !important;
  color: white;
  font-size: 8pt;
}
.save-button-profile:hover {
  padding: 10px 25px 10px 25px;
  background-color: #5e72e4 !important;
  color: white;
  font-size: 8pt;
}
.submit-button-profile {
  padding: 10px 25px 10px 25px;
  background-color: #5e72e4 !important;
  color: white;
  font-size: 8pt;
  opacity: 10 !important;
}
.submit-button-profile:hover {
  padding: 10px 25px 10px 25px;
  background-color: #5e72e4 !important;
  color: white;
  font-size: 8pt;
  opacity: 10 !important;
}
.currency-button {
  padding: 5% 35% 5% 35%;
  background-color: transparent !important;
  font-size: 10pt;
  color: #4385b1 !important;
  border: 1px solid #4385b1;
}
.currency-button-selected {
  padding: 5% 35% 5% 35%;
  background-color: #5e72e4 !important;
  font-size: 10pt;
  color: white !important;
  border: 1px solid #4385b1;
}
.profile-row * {
  color: gray;
  padding: 2.5%;
  font-size: 3vh;
}
.coin-profile {
  width: 6vh;
  margin-bottom: 0.55vh;
}

// @media only screen and (max-width: 1600px) {
//   .image-card {
//     position: relative;
//     width: 100%;
//     top: 4%;
//     border-radius: 100pt;
//   }
//   .name-app-coins * {
//     margin-right: 0%;
//     font-size: 1.5em;
//   }
//   .coin-app-card {
//     position: relative;
//     width: 10%;
//     left: 2.5%;
//     bottom: 0%;
//   }
//   .col-money > .money {
//     position: relative;
//     font-size: 1.5em;
//     top: 35%;
//   }
//   .col-button {
//     font-size: 1em;
//     margin-top: 6%;
//   }
//   .text-nowrap {
//     font-size: 1em;
//     margin-left: 0em;
//   }
//   .app-not-downloaded {
//     font-size: 1em;
//     display: block;
//     white-space: normal;
//     word-wrap: break-word;
//     line-height: 1.85em;
//   }
//   .button-download {
//     background-color: #dfdede;
//     font-size: 1em;
//     margin-bottom: 0em !important;
//     color: black !important;
//   }
// }
// @media only screen and (max-width: 1440px) {
//   .image-card {
//     position: relative;
//     width: 100%;
//     top: 25%;
//     border-radius: 100pt;
//   }
//   .name-app-coins * {
//     position: relative;
//     margin-right: 0%;
//     font-size: 0.8em;
//   }
//   .coin-app-card {
//     position: relative;
//     width: 17.5%;
//     left: 5%;
//     bottom: 0%;
//   }
//   .col-money * {
//     font-size: 1em;
//     margin-top: 15% !important;
//   }
//   .text-nowrap {
//     position: relative;
//     //margin-left: -7.5%;
//     font-size: 0.6em;
//   }
//   .app-not-downloaded {
//     font-size: 8pt;
//     display: block;
//     white-space: normal;
//     word-wrap: break-word;
//     line-height: 1.7em;
//   }
//   .button-download {
//     background-color: #dfdede;
//     font-size: 6pt;
//     padding: 10%;
//     color: black !important;
//   }
// }

// @media only screen and (max-width: 360px) {
//   .image-card {
//     position: relative;
//     width: 100%;
//     top: 25%;
//     border-radius: 100pt;
//   }
//   .name-app-coins * {
//     position: relative;
//     margin-right: 0%;
//     font-size: 0.75em;
//   }
//   .coin-app-card {
//     position: relative;
//     width: 12.5%;
//     left: 5%;
//     bottom: 0%;
//   }
//   .col-money > .money {
//     position: relative;
//     font-size: 0.75em;
//     top: 35%;
//   }
//   .col-button {
//     font-size: 1em;
//     margin-top: 6%;
//   }
//   .title-text {
//     font-size: 0.75em;
//   }
//   .text-nowrap {
//     margin-left: -7.5%;
//     font-size: 0.55em;
//   }
//   .app-not-downloaded {
//     font-size: 7pt;
//     display: block;
//     white-space: normal;
//     word-wrap: break-word;
//     line-height: 1.7em;
//   }
//   .button-download {
//     background-color: #dfdede;
//     font-size: 6pt;
//     padding: 10%;
//     color: black !important;
//   }
//   .coin-admin-nav {
//     width: 32.5%;
//     left: 5%;
//     margin-bottom: 2.5% !important;
//   }
//   .user-balance {
//     width: 40%;
//     font-size: 0.8em;
//     padding: 0;
//   }
// }
.navbar-vertical .navbar-brand > img {
  max-height: 3rem;
}
.not-clickable {
  opacity: 0.5;
}
.error-modal {
  position: relative;
  top: 35%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.text-download-withdraw {
  position: relative;
  z-index: 999 !important;
  color: #525f7f;
  opacity: 0.8;
  font-size: 15pt;
  font-weight: bold;
}
.button-exchange-withdraw {
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 60% !important;
  margin-top: 0 !important;
}
.button-exchange-withdraw:hover {
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 60% !important;
  margin: 0 !important;
}
.text-transfer-money {
  text-align: center !important;
  margin-top: 6%;
}
.container-info-button {
  background-color: #5e72e4;
  text-align: center !important;
  width: 30%;
  border-radius: 999px;
  cursor: pointer;
}
.info-button {
  color: white !important;
  font-weight: bold;
}
.navbar-vertical .navbar-brand > img {
  max-height: 3rem;
}
.not-clickable {
  opacity: 0.5;
}
.error-modal {
  position: relative;
  top: 35%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.disabled-content {
  pointer-events: none;
  opacity: 0.7;
}
.cookies-container {
}
.react-cookie-law-dialog {
  margin-top: 15% !important;
  margin: 35%;
  border-radius: 10pt;
  padding: 2.5% !important;
}
// .react-cookie-law-button-wrapper{
//   background-color: #5e72e4 !important;
// }
.react-cookie-law-accept-btn {
  display: block !important;
  background-color: #5e72e4 !important;
  padding: 7.5% !important;
  border-radius: 10pt;
  font-weight: bold !important;
  border: none !important;
}
.react-cookie-law-accept-btn:hover {
  display: block !important;
  background-color: #5e72e4 !important;
  padding: 7.5% !important;
  border-radius: 10pt;
  font-weight: bold !important;
  border: none !important;
}
.react-cookie-law-manage-btn {
  display: none !important;
}
.current-task {
  font-size: 0.75vw;
}
.container-current-task {
  padding: 0 !important;
}
.container-progress {
  margin-bottom: 0 !important;
}
.current-task {
  display: block;
}
.progress-bar-cont * {
  display: inline;
}
.main-progress {
  position: absolute;
  margin-top: 17.5px;
  margin-left: 1.75vw;
  width: 9vw !important;
}
.percentage-progress {
  font-size: 0.75vw;
}
.container-hidden {
  opacity: 0;
}
.app-col {
  width: 80%;
}
.app-col-logo {
  width: 150%;
}
.not-active-icon {
  opacity: 0.5;
}
.active-icon {
  opacity: 1;
}
.in-progress-task {
  background-color: cornsilk;
}
.img-logo-offerwall-mobile {
  width: 70%;
  margin-left: 45%;
  margin-top: 20% !important;
}
.text-coins-mobile-offerwall {
  font-size: 15pt;
  font-weight: bold;
  margin-left: 2.5%;
}
.coin-mobile-offerwall {
  width: 35%;
  margin-bottom: 10%;
  margin-left: 0%;
}
.mobile-progress-offerwall {
  margin-bottom: 0 !important;
}
.badge-mobile {
  position: relative;
}
.internal-offerwall {
  margin-top: 7%;
}
.img-logo-offerwall {
  width: 100%;
  margin-left: 15%;
}
.img-logo-offerwall-mobile {
  width: 70%;
  margin-top: 10%;
}
.text-download-offerwall {
  position: absolute;
  z-index: 999 !important;
  margin-top: 3.25%;
  color: #525f7f;
  opacity: 0.8;
  width: 100%;
  margin-left: 15%;
}
.button-exchange-offerwall {
  position: absolute;
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 10% !important;
  margin: 0 !important;
  right: 5% !important;
  bottom: 10%;
  cursor: pointer;
}
.button-exchange-offerwall:hover {
  position: absolute;
  background-color: #5e72e4;
  font-size: 10pt;
  color: white;
  padding: 5px;
  width: 10% !important;
  margin: 0 !important;
  right: 5% !important;
  bottom: 10%;
}
.exchange-icon-offerwall-mobile {
  position: relative;
  top: 25%;
  width: 90%;
  margin-bottom: 0%;
  right: 25%;
  cursor: pointer;
}
.col-balance > .money {
  position: relative;
  right: 8.5%;
}
.image-withdraw-header {
  width: 25%;
}
.image-and-text-withdraw {
  padding-bottom: 5%;
}

.container-horizontal {
  margin-top: 5%;
  margin-left: 10%;
  width: auto;
  height: auto;
}
.container-horizontal::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.container-horizontal > .methods {
  width: 20%;
  background-color: transparent;
  float: none;
  height: auto;
  margin: 0 1.25%;
  display: inline-block;
  cursor: pointer;
}
.container-horizontal > .methods * img {
  width: 100px !important;
}
.container-horizontal > .methods * .diff-img {
  width: 70px !important;
}
.container-horizontal > .methods * .diff-img-2 {
  width: 80px !important;
}
.methods-2 {
  text-align: center;
  border-radius: 25px;
  padding: 10% 0% 10% 0%;
  margin-bottom: 0%;
  height: auto;
  // transition: padding 0.5s;
}
.method-selected-paypal {
  border: 2px solid #5ff3da;
  padding: 30% 12% 30% 12%;
  // transition: padding 0.5s;
}

.method-selected-coinbase {
  border: 2px solid #049bf3;
  padding: 30% 12% 30% 12%;
  // transition: padding 0.5s;
}

.method-selected-amazon {
  border: 2px solid #f19999;
  padding: 30% 12% 30% 12%;
  // transition: padding 0.5s;
}

.method-selected-binance {
  border: 2px solid #f5dd63;
  padding: 30% 12% 30% 12%;
  // transition: padding 0.5s;
}

@media only screen and (max-width: 600px) {
  #prize-mobile{
    position: absolute;
    right: 5%;
  }
  #button-copy-mobile{
    position: absolute;
    right: 5%;
  }
  .container-horizontal {
    margin-top: 5%;
    margin-left: 0%;
    width: auto;
    height: auto;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .container-horizontal::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .container-horizontal > .methods {
    width: 40%;
    background-color: transparent;
    float: none;
    height: auto;
    margin: 0 1.25%;
    display: inline-block;
    zoom: 1;
  }
  .container-horizontal > .methods * img {
    width: 100px !important;
  }
  .container-horizontal > .methods * .diff-img {
    width: 65px !important;
  }
  .container-horizontal > .methods * .diff-img-2 {
    width: 75px !important;
  }
  .methods-2 {
    text-align: center;
    border-radius: 25px;
    padding: 20% 2% 20% 2%;
    margin-bottom: 5%;
    height: auto;
    // transition: padding 0.5s;
  }
  .method-selected-paypal {
    border: 2px solid #5ff3da;
    padding: 30% 6% 30% 6%;
    // transition: padding 0.5s;
  }

  .method-selected-coinbase {
    border: 2px solid #049bf3;
    padding: 30% 6% 30% 6%;
    // transition: padding 0.5s;
  }

  .method-selected-amazon {
    border: 2px solid #f19999;
    padding: 30% 6% 30% 6%;
    // transition: padding 0.5s;
  }

  .method-selected-binance {
    border: 2px solid #f5dd63;
    padding: 30% 6% 30% 6%;
    // transition: padding 0.5s;
  }

  .methods div img {
    width: 70%;
  }
  .image-and-text-withdraw {
    padding-bottom: 0%;
  }
  .col-balance > .money {
    position: relative;
    right: 11%;
  }
  .container-info-button {
    background-color: #5e72e4;
    text-align: center !important;
    width: 100%;
    border-radius: 999px;
    cursor: pointer;
  }
  .header-body1 {
    height: 32.5vw;
  }
  .mask1-mobile {
    position: absolute;
    width: 30vw;
    left: 45vw;
    bottom: 0;
    margin-top: 3.5%;
    margin-left: calc(-80% / 2);
  }
  .container-first-second-text {
    align-content: center;
    text-align: left;
    padding-left: 40vw;
    padding-right: 2vw;
    padding-top: 5vw;
  }
  .first-text {
    display: block;
    color: #dfdede;
    font-weight: bold;
    font-size: 4vw;
  }
  .second-text {
    display: block;
    color: #dfdede;
    font-size: 3.5vw;
  }
  .image-col {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    padding: 5% 0% 5% 2.5%;
    top: 50%;
  }
  .image-card {
    position: relative;
    max-width: 75%;
    max-height: 100%;
    border-radius: 100pt;
  }
  .name-app-coins {
    padding: 3px 0% 0% 0%;
  }
  .title-text {
    font-weight: bold;
    font-size: 5vw;
  }
  .coin-text {
    font-weight: bold;
    font-size: 4.25vw;
  }
  .coin-app-card {
    position: relative;
    width: 4.25vw;
    bottom: 0.15vw;
    left: 2.5%;
  }
  .app-not-downloaded {
    display: block;
    font-size: 2.75vw;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    line-height: 4vw !important;
  }
  .col-money {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    padding: 5% 0% 0% 0%;
    top: 50%;
    right: 3%;
  }

  .col-money > .money {
    font-size: 4vw;
  }
  .col-money > .col-12 {
    padding: 0;
  }

  .col-button {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    padding: 7% 0% 0% 5%;
    top: 50%;
    left: 0%;
  }

  .button-download {
    background-color: #dfdede;
    font-size: 3vw;
    padding: 5% 10% 5% 10%;
    color: black !important;
  }
  .button-go-app {
    background-color: #dfdede;
    font-size: 3vw;
    padding: 5% 22.5% 5% 22.5%;
    color: black !important;
  }
  .platform-card {
    position: absolute;
    left: 80%;
    top: 70%;
  }
  .platform-card > .app-store-app-card {
    width: 6%;
  }
  .platform-card > .android-app-card {
    width: 8%;
  }
  .user-balance {
    position: relative !important;
    left: 20%;
  }
  .user-balance-money {
    position: relative !important;
    left: 20%;
  }
  .coins-admin-nav {
    position: relative !important;
    left: 50%;
  }
  .current-task {
    font-size: 8pt;
  }
  .container-current-task {
    padding: 0 !important;
  }
  .container-progress {
    margin-bottom: 0 !important;
  }
  .current-task {
    display: block;
  }
  .progress-bar-cont * {
    display: inline;
  }
  .main-progress {
    position: absolute;
    top: 66%;
    margin-left: 6.5vw;
    width: 30vw !important;
  }
  .percentage-progress {
    font-size: 8pt;
  }
  .app-col {
    width: 125%;
  }
  .tasks-text {
    padding: 0;
  }
  .react-cookie-law-dialog {
    margin-top: 15% !important;
    margin: 5%;
    border-radius: 10pt;
    padding: 2.5% !important;
  }
}
.apps-paired {
  text-align: center;
  padding: 10px;
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  font-size: 7.5pt;
}
.date-paired > input {
  margin: 1%;
  display: inline !important;
  width: 100%;
}
.inline-items > .date-paired {
  margin: 1%;
  display: inline !important;
}
.inline-items {
  margin-left: 0%;
  margin-top: 0%;
}
.inline-items-withdraws * {
  display: block !important;
}

.over{
  background-color: rgb(124, 124, 243);
  color: white
}

#main-login-image{
  position: absolute;
  width: 100%;
  height: 600px;
  z-index: 0;
}
#main-login-image-2{
  width: 40%;
  top: 5%;
  position: absolute;
  right: 10%;
}
#text-welcome{
  font-size: 50pt;
  font-weight: bold;
  color: white;
}
#text-welcome-1{
  font-size: 22pt;
  font-weight: bold;
  color: white;
}
#text-welcome-2{
  margin-right: 20%;
  font-size: 14pt;
  color: #FFFFFF99,

}
#text-welcome-button-login{
  width: 215px;
border-radius: 10px;
border: none;
background-color: #F7F7FB;
-webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;  

}
#text-welcome-button-login-web{
width: 215px;
border-radius: 10px;
border: none;
background-color: #F7F7FB;
-webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;  

}
#text-welcome-button-login-txt{
  text-align: center;
  color: #4E228B;
  font-weight: 600;
  margin-top: 5px;
  font-size: 1.8vh;
}
.container-login-support{
  padding: 7.5%;
            background-image: url('https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1703239235994.png'); /* Replace 'your-image.jpg' with the path to your image */
            background-size: cover; /* This will make sure the background covers the entire container */
            background-position: center; /* Center the background image */
            height: 100vh; /* Set the height of the body to 100% of the viewport height */
            // color: #ffffff;
}
.container-form{
  background-color: #F7F7FB;
  border-radius: 10px;
  color: #170F49;
  font-size: 2vh;
}
.text-options {
  position:relative;
  display:inline-block;
  padding-bottom:20px;
  cursor: pointer;
}
.text-options:after {
  content:"";
  position:absolute;
  bottom:10px;
  left: 0;
  margin-left: -10%;
  display:inline-block;
  width:125%;
  border-bottom:#A958CC solid 2px;
    border-image:linear-gradient(90deg, #E692D0 -57.71%, #A958CC 36.28%, #6E3BC7 105.94%);
    border-image-slice: 1;
}
select {

  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

// select.custom-select:focus {
//   background-image:
//     linear-gradient(45deg, white 50%, transparent 50%),
//     linear-gradient(135deg, transparent 50%, white 50%),
//     radial-gradient(gray 70%, transparent 72%);
//   background-position:
//     calc(100% - 15px) 1em,
//     calc(100% - 20px) 1em,
//     calc(100% - .5em) .5em;
//   background-size:
//     5px 5px,
//     5px 5px,
//     1.5em 1.5em;
//   background-repeat: no-repeat;
//   border-color: green;
//   outline: 0;
// }
.custom-select{
  border:  none !important;
  font-size: 15px !important;
}
.custom-input-name{
  background-color: white;
  border-radius: 10px
}
input[type="text"] {
  background-color : #F7F7FB;
  border: none;
}
input[type="text"]::placeholder {
  color: #170F49;
}
#upload {
  display: none;
}
.upload-file-custom{
  background-color: #F7F7FB ;
  width: 100%;
  height: 240px;
  border-radius: 10px;
  padding: 5%;
}
.upload-file-custom-inside{
  background-color: none;
  border: #B1BFD0 dashed 1px;
  border-radius: 10px;
  width: auto;
  height: 210px
}
.text-p{
  font-size: 10px;
  text-align: center;
  padding-top:12.5%;
  padding-bottom: 0%;
}
.text-p2{
  font-size: 7px;
  color: #170F4978;
}
#container-upload-image{
  text-align: center;
}
.upload-file-custom-web{
  background-color: #F7F7FB ;
  width: 100%;
  height: 240px;
  border-radius: 10px;
  padding: 2%;
}
.upload-file-custom-inside-web{
  background-color: none;
  border: #B1BFD0 dashed 1px;
  border-radius: 10px;
  // width: auto;
  height: 200px;
}
.text-p-web{
  font-size: 10px;
  text-align: center;
  padding-top: 7%;
}
.text-p2-web{
  font-size: 7px;
  color: #170F4978;
}
#container-upload-image-web{
  text-align: center;
}
#icon-download-web{
  position: relative;
  top: 60px;
  // margin-top: 5% !important;
  // width: 10%;
}
#icon-download{
  position: relative;
  top: 0.3in;
  width: 40%;
}
.uploadOuter {
  text-align: center;
  padding: 20px;
  strong {
    padding: 0 10px
  }
}
.dragBox {
  width: 250px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
  input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
  }
}
.draging {
  transform: scale(1.1);
}
#preview {
  text-align: center;
  img {
    max-width: 100%
  }
}
.btn-custom{
  background: linear-gradient(90deg, #E692D0 -57.71%, #A958CC 36.28%, #6E3BC7 105.94%) !important;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-left: 25% !important;
  padding-right: 25% !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;

}
.btn-custom-web{
  background: linear-gradient(90deg, #E692D0 -57.71%, #A958CC 36.28%, #6E3BC7 105.94%) !important;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;

}
.btn-custom-2{
  background: linear-gradient(90deg, #E692D0 -57.71%, #A958CC 36.28%, #6E3BC7 105.94%) !important;
  width: 170px;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;

}
.btn-custom-2-resolve{
  background: linear-gradient(90deg, #6E3BC7 -7.71%, #3E3BC7 60.94%) !important;
  width: 170px;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;

}
.btn-custom-2-resolve-web{
  background: linear-gradient(90deg, #6E3BC7 -7.71%, #3E3BC7 60.94%) !important;
  width: 170px;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;

}
.btn-custom-2-web{
  background: linear-gradient(90deg, #E692D0 -57.71%, #A958CC 36.28%, #6E3BC7 105.94%) !important;
  width: 170px;
  border-radius: 10px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  color: #FFFFFF !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;

}
#image-uploaded{
  width: 35px;
}
.button{
  width:100%;
text-align: center;
cursor:pointer;
color:#d58521;
}

.close-button {
   -webkit-transition: -webkit-transform 1s ease-in-out;
   transition: transform 1s ease-in-out;
   transform-origin: center;
   transform-style: preserve-3D;
   width: 15px;
   height: auto;
 }
 
 .close-button:hover {
   -webkit-transform: rotate(360deg);
   transform: rotate(360deg);
 }
 .container-form-contact{
  background: radial-gradient(at top left,#6E3BC7 36%,#A958CC 90%, transparent 67.5%);
  // height: 400px;
 }

 .container-login-mobile{
  background-image: url("https://contact-bucket173531-dev.s3.eu-west-1.amazonaws.com/ORw0KGgoAA_1704211299814.png");
  height: 600px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
 }
 #image-login-people{
  position: relative;
  width: 400px;
  height: 400px;
}
.loading{
  position: absolute;
  top: 45%
}
.loading-web{
  position: absolute;
  width: 60%;
  top: 10%;
  right: 20%;
}
#img-customer-givvy{
  position: absolute;
  width: 45px;
  height: 45px;
  margin-top: - 3%
}
#img-customer-givvy-web{
  position: absolute;
  width: 45px;
  height: 45px;
  margin-top: - 1.5%
}
.jumbotron{
  background-color: #eeeef0  !important;
}
.jumb-web{
  background-color: #eeeef0  !important;
  padding-top: 2% !important;
}
#fixed-reply{
  position: fixed;
  bottom: 0;
  width: 90%;
  z-index: 1;
  height: 150px;
}
.single-query{
  height: 50%;
  overflow:scroll;
  padding-bottom: 10%;
}
.single-query::-webkit-scrollbar {
  display: none;
}
#user-block{
  background-color: #dedee4 !important;
}

.taskcomplete {
  width: 20px;
  margin-left: 102.5%;
  margin-top: -15%;
}
.taskcomplete-web {
  position: absolute;
  width: 20px;
  // left: 102.5%;
  right: 17.5%;
  margin-top: -30px

}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

#task-complete-p {
  font-family: sans-serif;
  color: green;
  font-size: 10px;
  font-weight: bold;
  margin: 1px auto;
  text-align: center;
  animation: text .4s linear .3s;
  -webkit-animation: text .3s linear .2s;
}

@-webkit-keyframes dash {
 0% {
   stroke-dashoffset: 1000;
 }
 100% {
   stroke-dashoffset: 0;
 }
}

@keyframes dash {
 0% {
   stroke-dashoffset: 1000;
 }
 100% {
   stroke-dashoffset: 0;
 }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
  }
}

.our-app-givvy{
  color: #595779 !important;
  font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 15px; /* 285.714% */
letter-spacing: 0.84px;
text-transform: capitalize;
  
}
.default-app{
  color: #595779 !important;
  font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 15px; /* 285.714% */
letter-spacing: 0.84px;
text-transform: capitalize;
}

#dateTime-message{
  font-size: 8px;
  margin-right: -2px;
  margin-bottom: -2px;
  margin-top: 5%;
}
#dateTime-message-web{
  font-size: 10px;
  margin-right: -2px;
  margin-bottom: -2px;
  margin-top: 5%;
}
#dateTime-message-main{
  font-size: 8px;
  margin-right: -10px;
  margin-bottom: -10px;
  margin-top: 5%;
}
#dateTime-message-list{
  font-size: 8px;
  margin-right: -10px;
  margin-bottom: -10px;
  margin-top: 5%;
}
#dateTime-message-list-web{
  font-size: 10px;
  margin-right: -10px;
  margin-bottom: -45px;
  margin-top: 5%;
}
.not-seen{
  background-color: red !important;
}
.logout-button{
  background: none !important;
  border: none;
  color: white;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
#form-web{
  padding-left: 15%;
  padding-right: 15%;
}
#provideUsername::placeholder{
  color: #9fa0a1;
}
.padd{
  margin-top: 10% !important;
}

.menuu{
  cursor: pointer;
}
#text-welcome-button-login-web{
  cursor: pointer;
}
.bold-text-login{
  opacity: 1 !important;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
.login-problem-modal{
  padding-right: 7.5%;
  padding-left: 5%;
  padding-top: 25%;
}
.login-problem-modal-content{
  border-radius: 20px !important;
}
.img-modal-login{
  width: 100%;
  height: 100%;
  margin-top: -20%;
}
.text-modal-loing{
  color: #170F49 !important;
  opacity: 0.7;
  font-size: 13px;
}
.btn-custom-modal-problem{
  background: linear-gradient(to right, #6E3BC7, #3E3BC7) !important;
  width: 120px;
  height: 40px;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 12px;
  border: none;
  border-radius: 7px !important;
}
.btn-custom-modal-problem:focus{
  color: white !important;
}

.btn-custom-modal-problem2{
  background: linear-gradient(to right, #E692D0, #915AF0) !important;
  width: 120px;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 12px;
  border: none;
  border-radius: 7px !important;
}
.btn-custom-modal-problem2:focus{
  color: white !important;
}
.white{
  color: white;
}

#modal-container{
  padding-top: 10rem !important;
}