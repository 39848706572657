$baseColor: white;
$borderRadius: 10px;
$imageBig: 100px;
$imageSmall: 50px;
$padding: 10px;

body {
   background-color: lighten($baseColor, 30%);
   * {
      box-sizing: border-box;
   }
}

html {
   scroll-behavior: smooth;
}
.navbar {
   overflow: hidden;
   background-color: white;
   position: fixed; /* Set the navbar to fixed position */
   top: 0%; /* Position the navbar at the top of the page */
   width: 100%; /* Full width */
   z-index: 10;
   padding: 2.5%;
}
.navbar > .pending {
   float: none;
   position: absolute;
   top: 50%;
   left: 40%;
   transform: translate(-50%, -50%);
}
.navbar > .waiting {
   float: none;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.navbar > .sended {
   float: none;
   position: absolute;
   top: 50%;
   left: 60%;
   transform: translate(-50%, -50%);
}
.navbar > button {
   padding: 0.5%;
   border-radius: 25px !important;
}
.table-users {
   margin-top: 10% !important;
}

.header {
   background-image: white;
   color: black;
   font-size: 1.5em;
   padding: 1rem;
   text-align: center;
   text-transform: uppercase;
}

td > img {
   border-radius: 50%;
   height: $imageSmall;
   width: $imageSmall;
}

.table-users {
   border: 1px solid darken(black, 5%);
   border-radius: $borderRadius;
   box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
   max-width: calc(100% - 2em);
   margin: 1em auto;
   overflow: hidden;
   width: 800px;
}
.btn-succes {
   background-color: rgb(29, 185, 84);
   margin-top: 25%;
   border: none !important;
   color: white;
   margin-left: 5%;
}
tr {
   margin: 5% !important;
}

table {
   width: 100%;
   border-collapse: collapse;

   td,
   th {
      color: darken(white, 10%);
      padding: $padding;
   }

   td {
      text-align: center;
      vertical-align: middle;
      word-wrap: break-word;
      max-width: 1px;
      &:last-child {
         font-size: 0.95em;
         line-height: 1.4;
         text-align: left;
      }
   }

   th {
      background-color: orangered;
      font-weight: 300;
   }

   tr {
      &:nth-child(2n) {
         background-color: #48616d;
         border-bottom: 2px solid black;
      }
      &:nth-child(2n + 1) {
         background-color: #484d6d;
         border-bottom: 2px solid black;
      }
   }
}

@media screen and (max-width: 700px) {
   td > img {
      border-radius: 50%;
      height: $imageSmall;
      width: $imageSmall;
   }
   table,
   tr,
   td {
      display: block;
   }

   td {
      max-width: 100% !important;
      &:first-child {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         width: $imageBig;
      }

      &:not(:first-child) {
         clear: both;
         margin-left: $imageBig;
         padding: 4px 20px 4px 90px;
         position: relative;
         text-align: left;

         &:before {
            color: lighten(black, 30%);
            content: "";
            display: block;
            left: 0;
            position: absolute;
         }
      }
      .btn-succes {
         background-color: rgb(29, 185, 84);
         margin-top: 20%;
         border: none !important;
         color: white;
      }

      &:nth-child(2):before {
         content: "Name:";
         color: yellow;
      }
      &:nth-child(3):before {
         content: "Phone:";
         color: yellow;
      }
      &:nth-child(4):before {
         content: "Addres:";
         color: yellow;
      }
      &:nth-child(5):before {
         content: "IBAN:";
         color: yellow;
      }
      &:nth-child(6):before {
         content: "epayKIN:";
         color: yellow;
      }

      th {
         color: black !important;
      }
   }

   tr {
      padding: $padding 0;
      position: relative;
      &:first-child {
         display: none;
      }
   }
}

@media screen and (max-width: 500px) {
   .navbar {
      overflow: hidden;
      background-color: white;
      position: fixed; /* Set the navbar to fixed position */
      top: 10%; /* Position the navbar at the top of the page */
      width: 100%; /* Full width */
      z-index: 10;
      padding-top: 10%;
      padding-bottom: 5%;
   }
   .table-users {
      margin-top: 30% !important;
   }
   .navbar > .pending {
      float: none;
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-50%, -50%);
   }
   .navbar > .waiting {
      float: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
   .navbar > .sended {
      float: none;
      position: absolute;
      top: 50%;
      left: 80%;
      transform: translate(-50%, -50%);
   }
   .header {
      background-color: white !important;
      color: lighten(black, 60%);
      font-size: 2em;
      font-weight: 700;
      padding: 0;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
   }
   .btn-succes {
      background-color: rgb(29, 185, 84);
      margin-bottom: 5%;
      border: none !important;
      color: white;
   }

   img {
      border: none;
      border-color: lighten($baseColor, 50%);
      height: $imageBig;
      margin: 0.5rem 0;
      width: $imageBig;
   }

   td  {
      max-width: 100% !important;
      &:first-child {
         background-color: #484d6d;
         border-bottom: 1px solid lighten(black, 30%);
         border-radius: $borderRadius $borderRadius 0 0;
         position: relative;
         top: 0;
         transform: translateY(0);
         width: 100%;
      }

      &:not(:first-child) {
         margin: 0;
         padding: 5px 1em;
         width: 100%;

         &:before {
            font-size: 0.8em;
            padding-top: 0.3em;
            position: relative;
         }
      }

      &:last-child {
         padding-bottom: 1rem !important;
      }
   }

   tr {
      background-color: lighten(#484d6d, 10%) !important;
      border: 1px solid lighten(black, 20%);
      border-radius: $borderRadius;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      margin: 0.5rem 0;
      padding: 0;
   }

   .table-users {
      border: none;
      box-shadow: none;
      overflow: visible;
   }
}
