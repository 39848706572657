.query-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Asegura que ocupe toda la altura de la pantalla */
  width: 100vw;
  /* Asegura que ocupe todo el ancho de la pantalla */
  max-height: 100vh;
  /* Evita que crezca más allá de la pantalla */
  max-width: 100vw;
  /* Evita que crezca más allá del ancho de la pantalla */
  position: relative;
  padding: 5% !important;
  background: transparent !important;
}

.query-container {
  padding: 20px;
  /* background: #1c1c3a; */
  color: #fff;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  /* Permite que ocupe el espacio restante */
  overflow-y: auto;
  /* Habilita el desplazamiento vertical */
  padding: 20px;
  /* background: #1c1c3a; */
  /* Fondo del contenedor */
  color: #fff;
  border-radius: 10px;
  text-align: center;
  scrollbar-width: none;
  /* Ocultar scrollbar en Firefox */
  -ms-overflow-style: none;
  /* Ocultar scrollbar en Internet Explorer y Edge */
}

.welcome-text {
  color: #C875D5;
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  margin-top: 30px;
}

.description {
  color: #BDBDBD;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.9%;
  /* 21.546px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
}

.queries2 {
  border-radius: 20px;
  border: 1px solid #7571B6;
  background: linear-gradient(180deg, rgba(57, 54, 92, 0.70) 0%, rgba(19, 17, 45, 0.70) 100%);
  box-shadow: 0px 4px 51.1px 0px #090818;
  backdrop-filter: blur(22.5px);
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.queries2-title {
  color: #FFF;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 40px;
  /* 153.846% */
  text-transform: capitalize;
  padding-left: 10px
}

.queries2-subtitle {
  color: #BDBDBD;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.9%;
  /* 21.546px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  padding-left: 10px
}

.query-item {
  /* background: #38385e; */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  text-align: left;
}

.query-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #DDD;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 153.9%;
  /* 30.78px */
  letter-spacing: 0.8px;
}

.query-body {
  margin-top: 10px;
  background: rgba(20, 18, 47, 0.60) !important;
  stroke-width: 0.6px;
  stroke: #55558E;
  box-shadow: 0px 2px 6.3px 0px rgba(0, 0, 0, 0.20) inset;
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 10px;
  color: #6C6B8F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  /* 164.286% */
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.query-user {
  color: #A8A8B4;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.query-date {
  color: #BDBDBD;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.9%;
  /* 15.39px */
  letter-spacing: 0.4px;
}

.go-back-button {
  background: #6a52a3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.go-back-button {
  position: fixed;
  /* Fija el botón en la parte inferior */
  bottom: 20px;
  /* Separa el botón del borde inferior */
  left: 50%;
  /* Centra horizontalmente */
  transform: translateX(-50%);
  background: #6a52a3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 43px;
  border: 0.5px solid #EB9BF6;
  background: linear-gradient(180deg, #CB79DB 0%, #B764B7 93.05%);
  /* Asegura que esté encima del contenido */
}

.query-body::after {
  content: "";
  position: absolute;
  width: 8px;
  /* Dot size */
  height: 8px;
  background-color: #EB9BF6;
  /* Pink color */
  border-radius: 50%;
  bottom: -4px;
  /* Slightly below the query item */
  left: 50%;
  transform: translateX(-50%);
}

/* Bottom center dot */

.queries2::before,
.queries2::after,
.queries2 .dot-top-right,
.queries2 .dot-bottom-left {
  content: "";
  position: absolute;
  width: 8px;
  /* Dot size */
  height: 8px;
  background-color: #EB9BF6;
  /* Pink color */
  border-radius: 50%;
}

/* Top-left corner */
.queries2::before {
  top: 10px;
  left: 10px;
}

/* Bottom-right corner */
.queries2::after {
  bottom: 10px;
  right: 10px;
}

/* Top-right corner */
.queries2 .dot-top-right {
  top: 10px;
  right: 10px;
}

/* Bottom-left corner */
.queries2 .dot-bottom-left {
  bottom: 10px;
  left: 10px;
}

.query-body-text-area {
  background: transparent !important;
  width: 100%;
  min-height: 25vh;
  border: none;
  border-radius: 15px;
  padding: 10px;
  color: #6C6B8F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  /* 164.286% */
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.query-body-text-area::placeholder {
  color: #595779;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 285.714% */
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.query-subtitle {
  font-size: 8px;
}


.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh !important;
  /* padding: 5% !important;
  padding-bottom: 5% !important;
  padding-top: 5% !important; */

}

.upload-icon {
  font-size: 30px;
  color: #C875D5;
  /* Icon color */
  margin-bottom: 10px;
}

.upload-icon>img {
  width: 100px;
  height: 100px;
}

.upload-text {
  font-size: 16px;
  font-weight: 600;
  color: white;
  letter-spacing: 0;
  line-height: 0.5;
}

.upload-text .highlight {
  color: #C875D5;
  /* Highlighted "here" */
  text-decoration: underline;
}

.upload-support {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0;
  line-height: 0.5;
}

.button-container {
  display: flex;
  justify-content: center;
  /* Centers buttons horizontally */
  align-items: center;
  /* Aligns items vertically */
  gap: 20px;
  /* Space between buttons */
  margin-top: 20px;
  width: 100%;
  /* Ensures full width */
}

/* Estilo base para ambos botones */
.custom-button {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 40px;
  /* Ajusta para igual ancho */
  min-width: 160px;
  /* Hace que ambos sean del mismo tamaño */
  border: none;
  border-radius: 30px;
  /* Bordes redondeados */
  background: linear-gradient(180deg, #C875D5 0%, #A764B7 100%);
  color: white;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

/* Efecto 3D de sombra */
.custom-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  top: 4px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.custom-button:hover::before {
  top: 2px;
}

/* Variantes de botón (colores iguales pero separados para futuras modificaciones) */
.resolved {
  background: linear-gradient(180deg, #C875D5 0%, #A764B7 100%);
}

.submit {
  background: linear-gradient(180deg, #C875D5 0%, #A764B7 100%);
}

.img-users-query {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px
}

.img-users-query-replies {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px
}

.resolved-icon {
  width: 20px !important;
  height: 20px !important;
}
.resolved-icon {
  width: auto;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .img-users-query-replies {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px
  }

  .query-wrapper {
    padding: 0% !important;
    background: transparent !important;
  }

  .welcome-text {
    font-size: 18px;
    /* Reduce el tamaño de texto */
    letter-spacing: 0.3px;
    line-height: 1.2;
  }

  .description {
    font-size: 12px;
    /* Texto más pequeño */
    letter-spacing: 0.4px;
  }

  .queries2 {
    padding: 15px;
    /* Reduce el padding general */
  }

  .queries2-title {
    font-size: 20px;
    /* Reduce el tamaño del título */
    line-height: 1.4;
  }

  .queries2-subtitle {
    font-size: 12px;
    /* Reduce el subtítulo */
  }

  .query-item {
    padding: 10px;
    /* Ajusta el padding */
  }

  .query-header {
    font-size: 16px;
    /* Tamaño más pequeño para el encabezado */
  }

  .query-body {
    font-size: 12px;
    /* Reduce el tamaño del texto en el cuerpo */
    line-height: 20px;
    /* Ajusta el espaciado */
    padding: 8px;
    /* Reduce el padding */
  }

  .query-user {
    font-size: 12px;
    /* Reduce el texto del usuario */
  }

  .query-date {
    font-size: 8px;
    /* Reduce el texto de la fecha */
  }

  .go-back-button {
    border-radius: 43px;
    border: 0.5px solid #EB9BF6;
    background: linear-gradient(180deg, #CB79DB 0%, #B764B7 93.05%);
  }

  .upload-icon {
    font-size: 30px;
    color: #C875D5;
    /* Icon color */
    margin-bottom: 10px;
  }

  .upload-icon>img {
    width: 50px;
    height: 50px;
  }

  .upload-text {
    font-size: 10px;
    font-weight: 600;
    color: white;
    letter-spacing: 0;
    line-height: 0.2;
  }

  .upload-text .highlight {
    color: #C875D5;
    /* Highlighted "here" */
    text-decoration: underline;
  }

  .upload-support {
    font-size: 8px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 0;
    line-height: 0.2;
  }

  .query-body-text-area {
    min-height: 15vh;
  }

  .upload-area {
    min-height: 15vh !important;
  }

  .custom-button {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 20px;
    /* Ajusta para igual ancho */
    min-width: 120px;
    /* Hace que ambos sean del mismo tamaño */
    border: none;
    border-radius: 30px;
    /* Bordes redondeados */
    background: linear-gradient(180deg, #C875D5 0%, #A764B7 100%);
    color: white;
    cursor: pointer;
    outline: none;
    position: relative;
    transition: all 0.3s ease-in-out;
    text-align: center;
  }

  /* Efecto 3D de sombra */
  .custom-button::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    top: 4px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .img-users-query {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px
  }

  .resolved-icon {
    width: auto;
    height: auto;
  }
}